import { CommonTagService, installationNoticeService, installationOrderService } from '@/api';
import { Paging } from '@/api/base';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { InstallAttachmentsList, InstallEmployeeList, ShopItemReList, ShopRequestList } from '@/resource/model';
import {
  dateFormat,
  debounce,
  isNullOrUndefinedForBaseType,
  limitFutureForTimePicker,
  messageError,
  translation
} from '@/utils';
import { Message, Tree } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';
import InstallAttachment from './install-attachment/install-attachment.vue';
import BatchSetAnnex from './batch-set-annex/batch-set-annex.vue';
import BatchSetingShopInfo from './batch-seting-shop-info/batch-seting-shop-info.vue';
import SelectEmployee from '@/views/installation-management/install-employee/select-employee/select-employee.vue';
import { NormalSelectOptions } from '@/resource/model/common';
import { TagsViewModule } from '@/store/modules/tags-view';

interface Node {
  id: string;
  level: number;
  label: string;
  children: Array<Node> | undefined;
  valueList: Array<ShopRequestList>;
}
@Component({
  name: 'AddInstallationOrder',
  components: {
    InstallAttachment,
    BatchSetAnnex,
    BatchSetingShopInfo,
    SelectEmployee
  }
})
export default class AddInstallationOrder extends Vue {
  public installNoticeId!: number;

  public title: string = '';

  public abstractContent: string = '';
  public shopNumber: number = 0;

  public time: number = 0;
  public timer: any = undefined;
  public route: any = undefined;

  public saveLoading = false;

  public selectAnnexVisible = false;

  public currentDeliveryShopName = '';
  public shopId: number = 0;

  public tagList: NormalSelectOptions = [];

  // 城市平铺数组的下标
  public index: number = 0;

  public selectShopInfoVisible: boolean = false;

  public filteredData: Array<Partial<ShopRequestList>> = [];

  public currentDeliveryProduct: Array<ShopItemReList> = [];

  /**
   * 选择安装联系人
   */

  public selectEmployeeVisible: boolean = false;

  public rowData!: ShopRequestList;

  /**
   * 名店明细
   */

  public shopTableOptions: OsTableOption<Partial<ShopRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public shopColumnOptions: Array<OsTableColumn<ShopRequestList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'index',
      label: 'common.index',
      minWidth: '50px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'shopName',
      label: 'addInstallationNotice.shopName',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },

    {
      prop: 'foremanName',
      label: 'addInstallationNotice.contactsPerson',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'foremanTel',
      label: 'addInstallationNotice.contactsPhone',
      minWidth: '105px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishDay',
      label: 'addInstallationNotice.finishDay',
      minWidth: '110px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as ShopRequestList).finishDay, 'yyyy-MM-DD');
      }
    },
    {
      prop: 'abstractContent',
      label: 'installationNotice.orderSummary',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'serveTime',
      label: 'installationOrder.serveTime',
      minWidth: '350px',
      showOverflowTooltip: true
    },
    {
      prop: 'budgetAmount',
      label: 'addInstallationNotice.budgetAmount',
      minWidth: '208px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return (row as ShopRequestList).budgetAmount.toFixed(2);
      }
    },
    {
      prop: 'amount',
      label: 'customerShop.installCharge',
      minWidth: '208px',
      showOverflowTooltip: true
    },

    {
      prop: 'installTag',
      label: 'addInstallationNotice.tag',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'serveItems',
      label: 'installationOrder.serveItem',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'addInstallationNotice.address',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `${(row as ShopRequestList).province}
        ${(row as ShopRequestList).city ? (row as ShopRequestList).city : ''}
        ${(row as ShopRequestList).district ? (row as ShopRequestList).district : ''}
        ${(row as ShopRequestList).address}`;
      }
    },
    {
      prop: 'showRemark',
      label: 'installationNotice.showRemark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public shopPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public shopTotal = 0;

  public selectedShopRows: Array<ShopRequestList> = [];

  /**
   * 产品明细
   */
  public productPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public productTotal = 0;

  public productTableOptions: OsTableOption<Partial<ShopItemReList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public productColumnOptions: Array<OsTableColumn<ShopItemReList>> = [
    {
      prop: 'index',
      label: 'common.index',
      minWidth: '50px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      fixed: true,
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).visibleWidth} × ${(row as ShopItemReList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).finishWidth} × ${(row as ShopItemReList).finishHeight}`;
      }
    },
    {
      prop: 'count',
      label: 'addInstallationNotice.installCount',
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   *  附件列表
   */

  // 安装通知单附件
  public installAttachments: Array<InstallAttachmentsList> = [];

  public selectShopInstallAttachments: Array<InstallAttachmentsList> = [];

  public shopCityTree: Array<Node> = [];
  public indexList: Array<string | number> = [];

  public currentlySelectedNode: string = '';
  public activeOption: string | number = '';

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public get addInstallOrderDisabled(): boolean {
    return this.selectedShopRows.length === 0;
  }

  /**
   * 门店明细表格行选中事件
   */
  public handleShopSelectionChange(selectedData: Array<ShopRequestList>): void {
    this.selectedShopRows = selectedData;
  }

  public shopClick(deliveryShop: ShopRequestList): void {
    if (this.filteredData.length === 0) {
      return;
    }
    deliveryShop.shopItemRelList.forEach((item, index) => {
      item.index = index + 1;
    });
    deliveryShop.fileList.forEach((item, index) => {
      item.index = index + 1;
    });

    this.selectShopInstallAttachments = deliveryShop!.fileList || [];
    this.currentDeliveryProduct = deliveryShop.shopItemRelList || [];
    this.currentDeliveryShopName = ` —— ${deliveryShop.shopName}`;
    this.shopId = deliveryShop.id;
    this.filteredData.forEach((item, index) => {
      if (item.id === deliveryShop.id) {
        this.index = index;
      }
    });
    this.productPagingData();
  }

  public shopPagingData(): void {
    this.shopTotal = this.filteredData.length;
    this.shopTableOptions.data = [];
    this.shopTableOptions.data.push(
      ...this.filteredData.slice(
        (this.shopPaging.currentPage - 1) * this.shopPaging.showCount,
        this.shopPaging.currentPage * this.shopPaging.showCount
      )
    );

    if (this.shopTotal > 0) {
      this.shopClick(this.shopTableOptions.data[0] as any);
    }
  }

  public productPagingData(): void {
    this.productTotal = this.currentDeliveryProduct.length;
    this.productTableOptions.data = this.currentDeliveryProduct.slice(
      (this.productPaging.currentPage - 1) * this.productPaging.showCount,
      this.productPaging.currentPage * this.productPaging.showCount
    );
  }

  // 批量设置-附件
  public openSelectAnnex(): void {
    this.selectAnnexVisible = true;
  }

  public handleSelectAnnex(selectAnnexList: Array<InstallAttachmentsList>): void {
    this.filteredData.forEach(item => {
      const ids = this.selectedShopRows.map(x => x.id!);
      if (ids.includes(item.id!)) {
        item.fileList = selectAnnexList;
      }
    });
    this.shopClick(this.filteredData[this.index] as any);
  }

  public setShopAnnexData(changeAnnexList: Array<InstallAttachmentsList>): void {
    this.filteredData.forEach(x => {
      if (x.id === this.shopId) {
        x.fileList = changeAnnexList;
      }
    });
    this.shopClick(this.filteredData[this.index] as any);
  }

  // 批量设置-门店信息
  public openselectShopInfo(): void {
    this.selectShopInfoVisible = true;
  }

  public handleShopInfo(form: any): void {
    this.shopTableOptions.data.forEach(item => {
      const ids = this.selectedShopRows.map(x => x.id!);
      if (ids.includes(item.id!)) {
        if (form.amount) {
          item.amount = form.amount;
        }
        if (form.foremanId) {
          item.foremanId = form.foremanId;
        }
        if (form.foremanName) {
          item.foremanName = form.foremanName;
        }
        if (form.foremanTel) {
          item.foremanTel = form.foremanTel;
        }
        if (form.serveEndTime) {
          item.serveEndTime = form.serveEndTime;
        }
        if (form.serveStartTime) {
          item.serveStartTime = form.serveStartTime;
        }
        if (form.serveTime) {
          item.serveTime = form.serveTime;
        }
        if (form.installTag) {
          item.installTag = form.installTag;
        }
        if (form.showRemark) {
          item.showRemark = form.showRemark;
        }
      }
    });
    this.$forceUpdate();
  }

  /**
   * 表格-安装联系人复值
   */
  public openEmployeeDialog(event: any, rowData: ShopRequestList): void {
    this.rowData = rowData;
    this.selectEmployeeVisible = true;
  }

  public handleSelectEmployeeConfirm(employee: InstallEmployeeList): void {
    this.shopTableOptions.data.forEach(item => {
      if (item.id === this.rowData.id) {
        Vue.set(item, 'foremanName', employee.realName);
        Vue.set(item, 'foremanId', employee.id);
        Vue.set(item, 'foremanTel', employee.mobile);
        console.log('item', item);
      }
    });
  }

  public created(): void {
    this.installNoticeId = Number(this.$route.query.id);
    this.loadDetails();
    this.getTagList();
    this.route = this.$route;
  }
  public activated(): void {
    this.installNoticeId = Number(this.$route.query.id);
    this.loadDetails();
    this.getTagList();
    this.route = this.$route;
  }

  /**
   * 验证发货明细，是否合法
   * @returns
   */
  public verifyDeliveryDetails(): boolean {
    const shopWithMissingProps = this.selectedShopRows?.find(
      x =>
        isNullOrUndefinedForBaseType(x.amount) ||
        isNullOrUndefinedForBaseType(x.foremanName) ||
        !x.serveTime ||
        isNullOrUndefinedForBaseType(x.amount)
    );
    console.log('shopWithMissingProps', shopWithMissingProps);
    if (shopWithMissingProps) {
      Message.warning(
        `${shopWithMissingProps.shopName} —— ${translation('addInstallationNotice.verifyShopPropFailed')}`
      );
      return false;
    }

    return true;
  }

  public async onSubmit(): Promise<void> {
    if (!this.verifyDeliveryDetails()) {
      return;
    }
    this.saveLoading = true;
    try {
      const obj: any = {};
      obj.shopRequestList = this.selectedShopRows;
      obj.shopRequestList.forEach((item: any) => {
        item.fileIdList = item.fileList.map((x: any) => x.id!);
        if (item.serveTime.length === 2) {
          Vue.set(item, 'serveStartTime', new Date(item.serveTime[0]));
          Vue.set(item, 'serveEndTime', new Date(item.serveTime[1]));
        }
        if (item.installTag) {
          Vue.set(item, 'installTag', JSON.stringify(item.installTag));
        }
        item.remark = item.showRemark;
      });
      obj.installNoticeId = this.installNoticeId;
      obj.status = 1;

      await installationOrderService.post(obj);
      Message.success(translation('operationRes.addSuccess'));
      this.next();
      this.loadDetails(false);
    } catch (error) {
      if (error) {
        messageError(error);
      }
    } finally {
      this.selectedShopRows.forEach((item: any) => {
        item.fileIdList = item.fileList.map((x: any) => x.id!);
        if (item.installTag) {
          Vue.set(item, 'installTag', JSON.parse(item.installTag));
        }
      });
      this.saveLoading = false;
    }
  }
  public async saveAndPush(): Promise<void> {
    if (!this.verifyDeliveryDetails()) {
      return;
    }
    this.saveLoading = true;
    try {
      const obj: any = {};
      obj.shopRequestList = this.selectedShopRows;
      obj.shopRequestList.forEach((item: any) => {
        item.fileIdList = item.fileList.map((x: any) => x.id!);
        if (item.serveTime.length === 2) {
          Vue.set(item, 'serveStartTime', new Date(item.serveTime[0]));
          Vue.set(item, 'serveEndTime', new Date(item.serveTime[1]));
        }
        if (item.installTag) {
          Vue.set(item, 'installTag', JSON.stringify(item.installTag));
        }
        item.remark = item.showRemark;
      });
      obj.installNoticeId = this.installNoticeId;
      obj.status = 2;

      await installationOrderService.saveAndPush(obj);
      Message.success(translation('operationRes.operationSuccess'));
      this.next();
      this.loadDetails(false);
    } catch (error) {
      if (error) {
        messageError(error);
      }
    } finally {
      // this.setLoading(false);
      this.selectedShopRows.forEach((item: any) => {
        item.fileIdList = item.fileList.map((x: any) => x.id!);
        if (item.installTag) {
          Vue.set(item, 'installTag', JSON.parse(item.installTag));
        }
      });
      this.saveLoading = false;
    }
  }

  /**
   * 获取提示标签下拉框数据
   */
  @debounce()
  public async getTagList(): Promise<void> {
    const tagStr = await CommonTagService.getOptionTagList();
    const options: NormalSelectOptions = [];
    JSON.parse(tagStr).forEach((item: string, index: string) => {
      options.push({
        label: item,
        value: 'key' + index
      });
    });
    this.tagList = options;
  }

  public handleNodeClick(nodeData: any): void {
    if (nodeData.level === 1) {
      return;
    }
    nodeData.valueList.forEach((item: { index: any }, index: number) => {
      item.index = index + 1;
    });
    this.filteredData = nodeData.valueList;
    this.selectedShopRows = [];
    (this.$refs.shopTable as OsTable).clearSelection();
    this.shopPagingData();
    this.currentlySelectedNode = nodeData.id;
  }

  /**
   * 生成树形结构数据
   * @param shopList
   * @returns
   */
  public handleTreeData(
    shopList: Array<ShopRequestList>
  ): {
    treeList: Array<Node>;
    indexList: Array<string | number>;
  } {
    const treeList: Array<Node> = [];
    const indexList: Array<string | number> = [];
    // 去重
    const cacheList = Array.from(new Set(shopList.map(x => x.province)));
    cacheList.forEach((item, index) => {
      const node: Node = {
        id: `${item}${index}`,
        label: '',
        level: 1,
        children: [],
        valueList: []
      };
      shopList.forEach(cur => {
        if (cur.province === item) {
          node.valueList.push(cur as ShopRequestList);
        }
      });
      node.label = `${item}(${node.valueList.length})`;
      treeList.push(node);
    });
    treeList.forEach(i => {
      const cityList = Array.from(new Set(i.valueList.map((x: ShopRequestList) => x.city)));
      cityList.forEach((item, index) => {
        const node: Node = {
          id: `${item}${index}`,
          label: '',
          level: 2,
          children: undefined,
          valueList: []
        };
        shopList.forEach(cur => {
          if (cur.city === item) {
            cur.fileIdList = [];
            cur.fileList = [];
            cur.foremanId = null;
            cur.foremanName = null;
            cur.foremanTel = null;
            cur.installTag = '';
            cur.serveTime = [
              dateFormat(cur.finishDay, 'YYYY/MM/DD') + ' 00:00:00',
              dateFormat(cur.finishDay, 'YYYY/MM/DD') + ' 23:59:59'
            ];
            cur.serveStartTime = dateFormat(cur.finishDay, 'YYYY/MM/DD') + ' 00:00:00';
            cur.serveEndTime = dateFormat(cur.finishDay, 'YYYY/MM/DD') + ' 23:59:59';
            cur.amount = cur.budgetAmount;
            cur.abstractContent = this.abstractContent;
            // 门店备注
            cur.showRemark = '';
            node.valueList.push(cur as ShopRequestList);
          }
        });
        node.label = `${item}(${node.valueList.length})`;
        i.children!.push(node);
        indexList.push(node.id);
      });
    });
    return { treeList, indexList };
  }
  public next(): void {
    let nextIndex = this.indexList.indexOf(this.currentlySelectedNode) + 1;
    if (nextIndex === this.indexList.length) {
      nextIndex = 0;
    }
    const nodeKey = this.indexList[nextIndex];
    this.selected(nodeKey);
    const node = (this.$refs.installationOrderTree as Tree).getNode(nodeKey);
    this.handleNodeClick(node.data);
  }
  public back(): void {
    let nextIndex = this.indexList.indexOf(this.currentlySelectedNode) - 1;
    console.log('nextIndex', nextIndex);
    if (nextIndex < 0) {
      nextIndex = this.indexList.length - 1;
      console.log('nextIndex1', nextIndex);
    }
    const nodeKey = this.indexList[nextIndex];
    this.selected(nodeKey);
    const node = (this.$refs.installationOrderTree as Tree).getNode(nodeKey);
    this.handleNodeClick(node.data);
  }

  public close(): void {
    clearInterval(this.timer);
    if (this.$route.name === 'AddInstallationOrder') {
      this.$router.go(-1);
    }
    TagsViewModule.DelView(this.route);
  }

  public automaticShutdown(): void {
    this.time = 3;
    this.timer = setInterval(() => {
      if (this.time > 0) {
        this.time--;
        console.log(this.time);
      } else {
        this.close();
      }
    }, 1000);
  }

  public selected(val: string | number): void {
    val && (this.activeOption = val);
    val && (this.$refs.installationOrderTree as Tree).setCurrentKey(val);
    const selected = (this.$refs.installationOrderTree as Tree).getCurrentNode();
    // 若当前组件有父节点 展开其所有祖宗节点
    if (
      (this.$refs.installationOrderTree as Tree).getNode(selected) &&
      (this.$refs.installationOrderTree as Tree).getNode(selected).parent
    ) {
      this.expandParents((this.$refs.installationOrderTree as Tree).getNode(selected).parent);
    }
    this.$emit('filterSelect', selected);
  }
  public expandParents(node: any): void {
    // 展开所有祖宗节点
    node.expanded = true;
    if (node.parent) {
      this.expandParents(node.parent);
    }
  }
  @debounce()
  private loadDetails(allow: boolean = true): void {
    this.shopCityTree = [];
    this.indexList = [];
    installationNoticeService
      .getInfo(this.installNoticeId)
      .then(res => {
        if (!res) {
          this.$router.go(-1);
          TagsViewModule.DelView(this.$route);
          Message.error(translation('addInstallationNotice.installIsNull'));
        }
        console.log(this.$route);
        this.title = `${res.customerName} | ${res.code}`;
        // 摘要赋值
        this.abstractContent = res.abstractContent;
        this.shopNumber = res.shopList.length || 0;
        if (this.shopNumber === 0) {
          this.automaticShutdown();
          return;
        }

        this.shopCityTree = this.handleTreeData(res.shopList as any).treeList;
        this.indexList = this.handleTreeData(res.shopList as any).indexList;

        this.$nextTick(() => {
          if (allow) {
            this.currentlySelectedNode = this.indexList[0] as string;
          }
          this.selected(this.currentlySelectedNode);
          const node = (this.$refs.installationOrderTree as Tree).getNode(this.currentlySelectedNode);

          (this.$refs.installationOrderTree as Tree).setCurrentKey(node.key);
          this.handleNodeClick(node.data);
        });
        // 附件传参
        this.installAttachments = res.installAttachmentList ?? [];
      })
      .catch(error => {
        messageError(error);
      });
  }
}
