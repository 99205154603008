import { DialogMixin } from '@/mixins/dialog';
import { CreateInstallationOrder, InstallEmployeeList } from '@/resource/model';
import { limitFutureForTimePicker, messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import SelectEmployee from '@/views/installation-management/install-employee/select-employee/select-employee.vue';
import { CommonTagService } from '@/api';
import { NormalSelectOptions } from '@/resource/model/common';
import { Form, Message } from 'element-ui';
@Component({
  components: { SelectEmployee }
})
export default class BatchSetingShopInfo extends mixins(DialogMixin) {
  public form: Partial<CreateInstallationOrder & { showRemark: string }> = {
    foremanId: null,
    foremanName: '',
    foremanTel: '',
    amount: null,
    serveTime: '',
    serveEndTime: '',
    serveStartTime: '',
    installTag: '',
    showRemark: ''
  };

  public formRules: { [prop in keyof Partial<CreateInstallationOrder>]: Array<Object> } = {
    // foremanName: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: string, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('installationOrder.selectInstallEmployee')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'change'
    //   }
    // ],
    // amount: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: number, callback: Function): void => {
    //       if (isNullOrUndefinedForBaseType(value)) {
    //         callback(new Error(translation('addInstallationNotice.inputBudgetAmount')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'blur'
    //   }
    // ]
  };
  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public tagList: NormalSelectOptions = [];

  public selectEmployeeVisible = false;

  public created(): void {}

  public dialogOpen(): void {
    this.getTagList();
    (this.$refs['installOrderForm'] as Form).resetFields();
    this.form = {
      foremanId: null,
      foremanName: '',
      foremanTel: '',
      amount: null,
      serveTime: '',
      serveEndTime: '',
      serveStartTime: '',
      installTag: '',
      remark: ''
    };
  }

  public async onSubmit(): Promise<void> {
    try {
      if (this.form.serveTime) {
        this.form.serveStartTime = this.form.serveTime[0];
        this.form.serveEndTime = this.form.serveTime[1];
      }
      Message.success(translation('operationRes.setSuccess'));
      this.$emit('confirm', this.form);
      this.closeDialog();
    } catch (error) {
      if (error) {
        Message.success(translation('operationRes.setFailed'));
        messageError(error);
      }
    } finally {
      this.setLoading(false);
    }
  }

  public async getTagList(): Promise<void> {
    const tagStr = await CommonTagService.getOptionTagList();
    const options: NormalSelectOptions = [];
    JSON.parse(tagStr).forEach((item: string, index: string) => {
      options.push({
        label: item,
        value: 'key' + index
      });
    });
    this.tagList = options;
  }

  public openEmployeeDialog(): void {
    this.selectEmployeeVisible = true;
  }

  public handleSelectEmployeeConfirm(employee: InstallEmployeeList): void {
    this.form.foremanName = employee.realName;
    this.form.foremanId = employee.id;
    this.form.foremanTel = employee.mobile;
  }
}
