import { InstallEmployService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { ResourceStatusEnum } from '@/resource/enum';
import { InstallEmployeeList, InstallEmployeeListQuery } from '@/resource/model';
import {
  dateFormat,
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  messageError,
  translation
} from '@/utils';
import { ElTable } from 'element-ui/types/table';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectEmployee extends mixins(DialogMixin, PagingMixin) {
  public tableOption: OsTableOption<InstallEmployeeList> = {
    loading: false,
    data: [],
    fit: true,
    radioSelection: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<InstallEmployeeList>> = [
    {
      prop: 'realName',
      label: 'installEmployee.name',
      minWidth: '120px',
      fixed: 'left'
    },
    {
      prop: 'mobile',
      label: 'installEmployee.mobile',
      minWidth: '120px'
    },
    {
      prop: 'nickname',
      label: 'installEmployee.nickname',
      minWidth: '120px'
    },
    {
      prop: 'gender',
      label: 'personnel.gender',
      minWidth: '80px',
      formatter: (row: object): string => {
        return (row as InstallEmployeeList).gender === 1 ? translation('common.man') : translation('common.woman');
      }
    },
    {
      prop: 'birthday',
      label: 'installEmployee.birthday',
      minWidth: '150px',
      formatter: (row: object): string => {
        return dateFormat((row as InstallEmployeeList).birthday!, 'YYYY-MM-DD');
      }
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px'
    },
    {
      prop: 'bindWechat',
      label: 'installEmployee.whetherBindWechat',
      minWidth: '80px',
      formatter: (row: object): string => {
        return (row as InstallEmployeeList).bindWechat
          ? translation('installEmployee.bound')
          : translation('installEmployee.unbound');
      }
    },
    {
      prop: 'province',
      label: 'customer.area',
      minWidth: '240px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        if (!(row as InstallEmployeeList).province) {
          return '--';
        }
        return `${(row as InstallEmployeeList).province}
        ${(row as InstallEmployeeList).city ? (row as InstallEmployeeList).city : ''}
        ${(row as InstallEmployeeList).district ? (row as InstallEmployeeList).district : ''}`;
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as InstallEmployeeList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<InstallEmployeeListQuery>> = [
    {
      type: 'Input',
      field: 'realName',
      label: 'installEmployee.name',
      option: {
        placeholder: 'personnel.inputRealName'
      }
    },
    {
      type: 'Input',
      field: 'mobile',
      label: 'installEmployee.mobile',
      option: {
        placeholder: 'personnel.inputMobile'
      }
    },
    {
      type: 'Input',
      field: 'city',
      label: 'customer.area',
      option: {
        placeholder: 'installEmployee.inputArea'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    },
    {
      type: 'Input',
      field: 'nickname',
      label: 'installEmployee.nickname',
      option: {
        placeholder: 'installEmployee.inputNickname'
      }
    }
  ];

  public selectedRow: InstallEmployeeList | null = null;

  private queryForm: Partial<InstallEmployeeListQuery> = {};

  public get confirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public dialogOpen(): void {
    this.loadData();
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public queryClick(): void {
    this.paging.currentPage = 1;
    this.reloadData();
  }

  public loadData(): void {
    this.tableOption.loading = true;
    InstallEmployService.getInstallEmployeeList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  public handleRadioSelectionChange(selectedRow: InstallEmployeeList): void {
    this.selectedRow = selectedRow;
  }

  public onSubmit(): void {
    this.$emit('confirm', this.selectedRow);
    this.closeDialog();
  }

  private reloadData(): void {
    this.clearSelection();
    this.loadData();
  }

  private clearSelection(): void {
    (this.$refs.employeeTable as ElTable).clearSelection();
    this.selectedRow = null;
  }
}
