import { InstallAttachmentsList } from '@/resource/model';
import { Component, Prop } from 'vue-property-decorator';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsTable } from '@/components';
import { mixins } from 'vue-class-component';
import { Table } from 'element-ui';
import { PagingMixin } from '@/mixins/paging';
import { Paging } from '@/api/base';
import { DialogMixin } from '@/mixins/dialog';

@Component({
  name: 'BatchSetAnnex',
  components: {}
})
export default class BatchSetAnnex extends mixins(PagingMixin, DialogMixin) {
  @Prop({ default: true, type: Array, required: false })
  public tableData!: Array<InstallAttachmentsList>;
  @Prop({ default: true, type: Boolean, required: false })
  public isMultiple!: boolean;
  public pageTotal = 0;
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public tableOption: OsTableOption<InstallAttachmentsList> = {
    loading: false,
    data: [],
    fit: true,
    radioSelection: !this.isMultiple
  };
  /**
   * 表格列配置
   */
  public columnOptions: Array<OsTableColumn<InstallAttachmentsList>> = [
    {
      prop: 'name',
      label: 'installationNotice.fileName',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'extension',
      label: 'installationNotice.fileExtension',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'size',
      label: 'installationNotice.fileSize',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  public selectedRow: InstallAttachmentsList | null = null;
  public selectedRows: Array<InstallAttachmentsList> = [];
  public dialogOpen(): void {
    console.log('打开弹窗');
    this.handlePaging();
  }
  public created(): void {
    if (this.isMultiple) {
      this.columnOptions.unshift({
        type: 'selection',
        prop: 'path',
        label: '',
        reserveSelection: true,
        fixed: true
      });
    }
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public handleRadioSelectionChange(selectedValue: InstallAttachmentsList): void {
    this.selectedRow = selectedValue;
  }

  public handleSelectionChange(selectedValues: Array<InstallAttachmentsList>): void {
    this.selectedRows = [...selectedValues];
  }

  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.serviceItemTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.serviceItemTable as OsTable).clearRadioSelection();
    }
  }

  public handlePaging(): void {
    this.pageTotal = this.tableData.length;
    this.tableOption.data = [];
    this.tableOption.data.push(
      ...this.tableData.slice(
        (this.paging.currentPage - 1) * this.paging.showCount,
        this.paging.currentPage * this.paging.showCount
      )
    );
  }
}
