import minioService from '@/api/minio';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { Operation, OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { InstallAttachmentsList, UploadedFile } from '@/resource/model';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UploadFile } from '@/views/dialogs';
import { downloadFileByPath, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElTable } from 'element-ui/types/table';
import BatchSetAnnex from '../batch-set-annex/batch-set-annex.vue';
@Component({
  components: { UploadFile, BatchSetAnnex }
})
export default class InstallAttachment extends Vue {
  @Prop({
    required: true,
    type: Array
  })
  public data!: Array<InstallAttachmentsList>;
  /**
   * 标题展示使用
   */
  @Prop({
    required: true,
    type: String
  })
  public shopName!: string;

  @Prop({ default: true, type: Array, required: false })
  public installAttachments!: Array<InstallAttachmentsList>;

  /**
   * 是否显示操作按钮区域
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showOperation!: boolean;

  /**
   * 表格工具栏配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      operationType: 'upload1',
      slot: 'start',
      label: 'button.upload',
      type: 'primary',
      icon: 'el-icon-upload',
      handleClick: this.openUploadDialog
    },
    {
      operationType: 'upload',
      slot: 'start',
      label: 'button.selectAnnex',
      type: 'primary',
      handleClick: this.openSelectDialog
    },
    {
      operationType: 'batchDelete',
      slot: 'start',
      label: 'button.delete',
      icon: 'el-icon-delete',
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];

  public tableOptions: OsTableOption<InstallAttachmentsList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<InstallAttachmentsList>> = [
    {
      type: 'selection',
      prop: 'path',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'index',
      label: 'common.index',
      width: '50px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'name',
      label: 'installationNotice.fileName',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'extension',
      label: 'installationNotice.fileExtension',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'size',
      label: 'installationNotice.fileSize',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `
        ${((row as InstallAttachmentsList).size / 1024).toFixed(0)}
        `;
      }
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOptions: RowOperation<InstallAttachmentsList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'download',
        type: 'text',
        label: 'button.ClickDownload',
        icon: 'el-icon-download',
        size: 'small',
        loading: false,
        handleClick: this.download
      }
    ]
  };

  public selectedFileRows: Array<InstallAttachmentsList> = [];

  public uploadVisible = false;
  public selectAnnexVisible = false;

  /**
   * 待删除的文件
   */
  private pendingDeleteFiles: Array<string> = [];

  public created(): void {
    minioService.init();
    this.data.forEach((item, index) => {
      item.index = index + 1;
    });
    this.tableOptions.data = this.data;
  }

  public setAnnex(fieldList: Array<InstallAttachmentsList>): void {
    const ids = this.tableOptions.data.map(x => x.id!);
    let fileList: any[] = [];
    fieldList.forEach(item => {
      if (!ids.includes(item.id)) {
        fileList.push(item);
      }
    });
    fileList = [...this.tableOptions.data, ...fileList];
    this.$emit('setParentData', fileList);
  }

  public handleSelectionChange(selectedFileRows: Array<InstallAttachmentsList>): void {
    this.selectedFileRows = selectedFileRows;
  }

  /**
   * 表格默认全选
   */
  public tableSelectAll(): void {
    this.$nextTick(() => {
      for (const item of this.data) {
        ((this.$refs.tableRef as Vue).$refs.osTable as ElTable).toggleRowSelection(item, true);
      }
      this.selectedFileRows = this.data;
    });
  }

  public async download(attachment: InstallAttachmentsList, btn: Operation<InstallAttachmentsList>): Promise<void> {
    try {
      btn.loading = true;
      const path = await minioService.getDownLoadPath(attachment.path);
      downloadFileByPath(attachment.name, path);
    } catch (error) {
      messageError(error);
    } finally {
      btn.loading = false;
    }
  }

  public async batchDelete(): Promise<void> {
    try {
      const paths = this.selectedFileRows.map(x => x.path);
      this.tableOptions.data = this.tableOptions.data.filter(x => !paths.includes(x.path));
      this.selectedFileRows = [];
      (this.$refs.tableRef as OsTable).clearSelection();
      this.pendingDeleteFiles.push(...paths);
      Message.success(translation('operationRes.deleteSuccess'));
    } catch (error) {
      Message.success(translation('operationRes.deleteFailed'));
      console.error(error);
    }
  }

  public openUploadDialog(): void {
    this.uploadVisible = true;
  }
  public openSelectDialog(): void {
    this.selectAnnexVisible = true;
  }

  /**
   * 删除minio已上传的文件
   * 需要父组件在合适的时机手动调用，
   * 因为某些情况比如编辑时，可能还没点保存，直接先删除文件会导致minio的文件被删除，而后续通过路径下载时会下载失败
   */
  public deleteMinioFiles(): void {
    minioService.removeFiles(this.pendingDeleteFiles).catch(error => {
      messageError(error);
    });
  }

  /**
   * 上传附件
   * @param requestOptions
   */
  private async handleAttachmentUploaded(files: Array<UploadedFile>): Promise<void> {
    this.uploadVisible = false;
    const fileList = [...this.tableOptions.data, ...files];
    this.$emit('setParentData', fileList);
  }

  @Watch('selectedFileRows')
  private handleSelectionChanged(rows: Array<InstallAttachmentsList>): void {
    this.operationOptions.forEach(option => {
      if (option.operationType === 'batchDelete') {
        option.disabled = rows.length === 0;
      }
    });
  }

  // @Emit()
  // private sync(data: Array<InstallAttachmentsList>): Array<InstallAttachmentsList> {
  //   return data;
  // }

  @Watch('data')
  private handleDataChanged(): void {
    this.data.forEach((item, index) => {
      item.index = index + 1;
    });
    this.tableOptions.data = this.data;
  }
  // @Watch('tableOptions.data')
  // private setParentDataChanged(): void {
  //   this.$emit('setParentData', this.tableOptions.data);
  // }
}
